import React from "react"

import Testimonial from "./testimonial"
import { useStaticQuery, graphql } from "gatsby" // to query for image data

const T = props => {
  const image = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "profiles/ricardo_neurored.png" }) {
        base
        childImageSharp {
          gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
        }
      }
    }
  `)
  return (
    <Testimonial
      by="Ricardo Urech, BU Manager for Road Transport and Warehouse,
      Neurored"
      text="Thanks to Cargo-Planner and their seamless integration with us, our
  customers have been able to speed up planning operations and to
  optimize their shipments allowing them to become more efficient and
  profitable."
      image={image}
      {...props}
    ></Testimonial>
  )
}

export default T
