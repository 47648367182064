import React from "react";
import { CheckCircleIcon } from "@heroicons/react/outline"

const CheckboxList = (props) => {
  return (
    <ul
      role="list"
      className="space-y-5 my-7 dark:border-gray-700"
    >
      {props.items.map((i, index) => (
        <ListItem key={index}>{i}</ListItem>
      ))}
    </ul>
  );
};

const ListItem = (props) => {
  return (
    <li className="flex space-x-3">
        <div>
            <CheckCircleIcon className="h-8 w-8 text-primary" />
        </div>
      <span className="leading-normal text-xl dark:text-white">
        {props.children}
      </span>
    </li>
  );
};

export default CheckboxList;
