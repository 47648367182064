import { React } from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TestimonialRicardo from "../components/testimonials/ricardo_neurored"
import FeaturesWithImages from "../components/features_with_images"
import Api from "../components/api"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDolly } from "@fortawesome/free-solid-svg-icons"
import { ShoppingCartIcon, TruckIcon } from "@heroicons/react/outline"

import { graphql } from "gatsby" // to query for image data
import { GatsbyImage } from "gatsby-plugin-image"

const DeveloperPage = ({ data }) => (
  <Layout>
    <SEO
      title="Developers"
      description="Use our load planning API for a quick integrations with your current ERP, TMS or WMS. Add load planning functionality to your application today."
    />
    
    <div className="md:pt-20 pb-48" id="about">
      <div className="container flex flex-wrap md:mb-12 px-6 mx-auto justify-center items-center">
        <div className="w-full md:w-1/2 pl-3 pr-5 text-2xl">
          <h1 className="my-4 text-4xl md:text-6xl font-black ">
          Integration made easy
        </h1>
          <p className="mt-12">We understand that integrating new software solutions into your existing systems can be daunting. That's why we've made it our mission to make the integration process as easy and straightforward as possible for our clients.</p>
          <p className="mt-6">Do you already have an <strong>ERP</strong>, <strong>TMS</strong>, <strong>WMS</strong> or custom built portal? With our <strong>API</strong> and <strong>SDK</strong> you can get started in minutes.</p>
          <Link 
            to="/docs/developer/"
            className="mt-12 inline-block text-lg font-semibold bg-primary text-white rounded-lg text-center px-6 py-3 shadow-xl hover:bg-gray-700 uppercase"
          >
            Get started
          </Link>
        </div>
        <div className="w-full md:w-1/2 pt-6 md:pt-0">
          <GatsbyImage
            image={data.integration.childImageSharp.gatsbyImageData}
            alt="integration"
          />
        </div>
      </div>
      <FeaturesWithImages
      features={[
        {
          title: "Embed in your application",
          image: data.integrationIframe,
          content: (
            <span>
              Our application can be embedded into your existing software, so you can provide your users with a more robust and complete solution. 
              <br /><br />Our flexible and customizable options will tailor the integration to your specific needs.
            </span>
          ),
        },
      ]}
    ></FeaturesWithImages>
      <Api />
      <TestimonialRicardo className="w-full md:my-24 text-white bg-primary" />
      <div className="container mx-auto py-12 flex flex-wrap justify-center">
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <ShoppingCartIcon className="h-16 w-16 mx-auto text-primary" />
          <p className="font-bold text-xl m-4"> E-commerce</p>
          <p className="text-gray-700 leading-loose text-lg">
            Calculate how many pallets or cartoons that are required for your
            customer's shopping carts - immediately in the checkout
          </p>
        </div>
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <TruckIcon className="h-16 w-16 mx-auto text-primary" />
          <p className="font-bold text-xl m-4">Forwarders</p>
          <p className="text-gray-700 leading-loose text-lg">
            Let your customers see how many containers, trailers, pallets or air
            containers are needed for the shipment - and provide them with a
            more precise quotation and more transparency
          </p>
        </div>
        <div className="w-1/2 md:w-1/4 text-center px-2 py-2">
          <FontAwesomeIcon
            icon={faDolly}
            className="text-primary h-16 w-16"
            size="3x"
          />
          <p className="font-bold text-xl m-4">Logistics service providers</p>
          <p className="text-gray-700 leading-loose text-lg">
            Add an additional service when booking transport through your
            platform by letting your users determine the best way to transport
            their cargoes
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export const query = graphql`
  {
    integration: file(relativePath: { eq: "screens/integration-example-iframe.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 720, quality: 100, layout: CONSTRAINED)
      }
    }
    integrationIframe: file(relativePath: { eq: "screens/integration-example-iframe-interactive.png" }) {
      base
      childImageSharp {
        gatsbyImageData(width: 600, quality: 100, layout: CONSTRAINED)
      }
    }
  }
`

export default DeveloperPage
