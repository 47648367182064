import React from "react";
import Typewriter from 'typewriter-effect/dist/core';
import { outer, dot, green, amber, red } from "./api.module.css"
import CheckboxList from "./checkbox_list";
import apiContainerExample from "../images/containers/container-example.png"

class ApiShowcase extends React.Component {
  state = {
    typewriterStarted: false,
    typewriterCompleted: false
  }

  inputObject = {
    length_dim: "M",
    weight_dim: "KG",
    items: [
      {
        "label": "MY CARGO",
        "l": 11,
        "w": 1,
        "h": 1,
        "wt": 25,
        "qty": 4
      },
    ],
    container_types: [
          {
            name: "MY CONTAINER",
            L: 12,
            W: 2,
            H: 2,
            payload: 20000,
          },
        ] 
  };

  outputObject = {
    response: {
      containers: [
        {
          "id": 2961877255,
          "name": "MY CONTAINER",
          "WT": 100.0,
          "volume": 44.0,
          "items_count": 4,
          "image": "data:image/png;base64..."

      }
    ],
    },
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const observer = new IntersectionObserver((entries) => {
      if(!this.state.typewriterStarted && entries[0].intersectionRatio > 0.25) {
       this.setupWriter()
      }
    }, { threshold: 0.25 });
    observer.observe(document.querySelector("#typewriterContainer"));
  }

  setupWriter() {
    this.setState({...this.state, typewriterStarted: true})
    const typewriter = new Typewriter('#typewriter', {
      autoStart: false,
      loop: false,
      delay: 20
    })
    typewriter.typeString(this.getInputString()).callFunction(() => {
      typewriter.stop();
      const cursor =
        document.getElementsByClassName(
          "Typewriter__cursor"
        );
      cursor.item(0)?.remove();
      this.setState({...this.state, typewriterCompleted: true});
    })
    typewriter.start()
  }

  isObject(obj, key) {
    return typeof obj[key] === "object";
  }

  isArray(obj, key) {
    return Array.isArray(obj[key]);
  }

  getTextForPropertyType(val) {
    let spanClass = typeof val;
    let value = val;
    if (typeof val === "string") {
      if (value === "[" || value === "{") {
        spanClass = "undefined";
      } else {
        value = `"${value}"`;
      }
    }
    return `<span class="${spanClass}">${value}</span>`;
  }

  getSpanClass(type) {
    
  }

  createRow(value, key, obj, depth) {
    const objectKeys = Object.keys(obj);
    const isLast = objectKeys[objectKeys.length - 1] === key;
    const endCharacter = isLast ? "" : ",";
    return {
      text: isNaN(key)
        ? `${key}: ${this.getTextForPropertyType(value)}${endCharacter}`
        : this.getTextForPropertyType(value) + endCharacter,
      depth,
    };
  }

  createObjectOrArrayStartRow(
    character,
    key,
    depth
  ) {
    return {
      text: isNaN(key) ? `${key}: ${character} ` : character,
      depth,
    };
  }

  createObjectOrArrayEndRow(
    character,
    key,
    obj,
    depth
  ) {
    const objectKeys = Object.keys(obj);
    const isLast = objectKeys[objectKeys.length - 1] === key;
    const endCharacter = isLast ? "" : ",";
    return {
      text: character + endCharacter,
      depth,
    };
  }

  getRowsFromObject(object) {
    let depth = 1;
    const rows = [];

    const iterate = (obj) => {
      Object.keys(obj).forEach((key) => {
        if (this.isArray(obj, key)) {
          rows.push(this.createObjectOrArrayStartRow("[", key, depth));
          depth++;
          iterate(obj[key]);
          depth--;
          rows.push(this.createObjectOrArrayEndRow("]", key, obj, depth));
        } else if (this.isObject(obj, key)) {
          rows.push(this.createObjectOrArrayStartRow("{", key, depth));
          depth++;
          iterate(obj[key]);
          depth--;
          rows.push(this.createObjectOrArrayEndRow("}", key, obj, depth));
        } else {
          rows.push(this.createRow(obj[key], key, obj, depth));
        }
      });
    };
    iterate(object);
    return rows;
  }

  getInputString() {
    const inputStartRow = {
      text: "<span style='color:#C0AD60'>const</span> input = {",
      depth: 0,
    };
    const inputEndRow = { text: "}", depth: 0 };
    const rowsFromObject =
      this.getRowsFromObject(this.inputObject);
    return [inputStartRow, ...rowsFromObject, inputEndRow]
      .map(
        (row) =>
          `<span style="margin-left:${row.depth * 12}px">${row.text}</span>`
      )
      .join("<br>");
  }

  getSpanStyle(depth) {
    return {
      display: "block",
      marginLeft: `${depth * 8}px`,
    };
  }

  render() {
    const output = () => {
      return this.getRowsFromObject(this.outputObject).map((item, index) => (
        <span
          dangerouslySetInnerHTML={{ __html: item.text }}
          style={this.getSpanStyle(item.depth)}
          key={index}
        ></span>
      ));
    };


    return (
      <section>
        <div className="w-full container mt-6 mx-auto lg:space-y-20 lg:px-6">
          <div className="items-center gap-8 lg:grid lg:grid-cols-12 xl:gap-16">
            <div className="lg:col-span-6 sm:text-lg py-12 px-12">
              <h2 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900">
                Use our simple API
              </h2>
              <CheckboxList
                items={[
                  <span>
                    Simple API for quick integrations
                    <p className="font-light py-1 text-gray-500">
                      Grab your API key and and start creating loadplans now.
                    </p>
                  </span>,
                  <span>
                    Clear documentation
                    <p className="font-light py-1 text-gray-500">
                      Plentyful of examples for almost every scenario.
                    </p>
                  </span>,
                  <span>
                    Extensive support
                    <p className="font-light py-1 text-gray-500">
                      Our team is always available to provide support and guidance throughout the integration process.
                    </p>
                  </span>,
                ]}
              />
            </div>
            <div className="lg:col-span-6 w-full flex flex-col relative">
              <div className={`${outer} bg-purple-800`}>
                <div className={`${dot} ${red}`}></div>
                <div className={`${dot} ${amber}`}></div>
                <div className={`${dot} ${green}`}></div>
                <span className="absolute left-[50%] translate-x-[-50%] text-white">
                  API Example
                </span>
              </div>

              <div id="typewriterContainer" className="min-h-[1200px] md:min-h-[610px] relative flex flex-col md:flex-row justify-between">
                <div className="flex-1 relative">
                  <div id="typewriter"></div>
                </div>
                <div className="border-l border-gray-100 opacity-20"></div>
                <div className="flex-1">
                  <div className={`opacity-0 transition-opacity ease-in ${this.state.typewriterCompleted === true && "opacity-100"}`} >
                    <div>
                      {output()}
                    </div>
                    <div className="w-full">
                      <img className="mx-auto max-w-[300px]" src={apiContainerExample} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ApiShowcase;
